import { Box, Grid, Stack, SvgIcon } from '@mui/material';
import { CONTENT_WIDTH, PageLayoutContainer } from '@aph/components/layouts/page-layout-container';
import { useFeatureToggle } from '~/model/feature-toggle/useFeatureToggle';
import ApotekLogo from './apotek_logo.svg';
import { FooterLinks } from './footer-links/footer-links.component';
import FooterWave from './footer_wave.svg';
import { footerLinks, footerShortcutLinks } from './links/links-data';
import { PartnersAndLegal } from './partners-and-legal/partners-and-legal.component';
import { ShortcutLinks } from './shortcut-links/shortcut-links.component';
import { SocialIcons } from './social-icons/social-icons.component';

export const Footer = () => {
  const { isEnabled: isTipsAndAdviceToggleEnabled } = useFeatureToggle(
    'aph_features_tips_advice_page',
  );
  return (
    <div className="mt-4 w-full">
      <SvgIcon
        viewBox="0 0 1440 102"
        preserveAspectRatio="none"
        sx={{ height: 'auto', width: '100%', marginBottom: { xs: -2, sm: -2 } }}
      >
        <FooterWave />
      </SvgIcon>
      <div className="bg-inverse relative flex h-fit min-h-[732px] w-full flex-col items-center pt-4">
        <footer className="flex w-full flex-col items-center">
          <PageLayoutContainer>
            <div className="flex w-full flex-col md:flex-row">
              <div className="pl-1 md:mr-3 md:min-w-[216px] lg:mr-4 xl:min-w-[248px]">
                <SvgIcon inheritViewBox sx={{ height: 50, width: 196 }}>
                  <ApotekLogo />
                </SvgIcon>
              </div>
              <Box
                maxWidth={CONTENT_WIDTH}
                height="fit-content"
                display="grid"
                rowGap={{ xs: 0, sm: 2 }}
                columnGap={{ xs: 0, sm: 2 }}
                width="100%"
                gridTemplateColumns={{
                  xs: 'repeat(1, minmax(0, 1fr))',
                  sm: 'repeat(3, minmax(0, 1fr))',
                  lg: 'repeat(4, minmax(0, 1fr))',
                  xl: 'repeat(6, minmax(0, 1fr))',
                }}
              >
                <Grid
                  item
                  gridColumn={{
                    lg: '1',
                    xl: '1 / span 3',
                  }}
                  display={{ xs: 'none', sm: 'none', lg: 'block' }}
                />
                <Grid item marginLeft={1}>
                  <ShortcutLinks
                    links={footerShortcutLinks.map((link) => {
                      if (
                        link.feature === 'aph_features_tips_advice_page' &&
                        !isTipsAndAdviceToggleEnabled
                      ) {
                        return { ...link, title: 'Tips och råd' };
                      }
                      return link;
                    })}
                  />
                </Grid>
                <Grid item marginTop={{ xs: 3, sm: 0 }} marginBottom={{ xs: 1, sm: 0 }}>
                  <Stack spacing={{ xs: 1, sm: 4 }}>
                    <FooterLinks linksData={footerLinks.about} />
                    <FooterLinks linksData={footerLinks.services} />
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={{ xs: 1, sm: 4 }}>
                    <FooterLinks linksData={footerLinks.help} />
                    <FooterLinks linksData={footerLinks.popularCategories} />
                  </Stack>
                </Grid>
              </Box>
            </div>
            <Box marginTop="auto" paddingX={1}>
              <SocialIcons />
              <PartnersAndLegal />
            </Box>
          </PageLayoutContainer>
        </footer>
      </div>
    </div>
  );
};
