import React from 'react';
import FacebookFooter from '@aph/icons/facebook_footer_new.svg';
import InstagramFooter from '@aph/icons/instagram_footer_new.svg';
import LinkedinFooter from '@aph/icons/linkedin_footer.svg';
import YoutubeFooter from '@aph/icons/youtube_footer_new.svg';

export type FooterLinkType = {
  href: string;
  title: string;
  feature?: string;
  isEpiLink?: boolean;
};

type FooterLinkListType = {
  header: FooterLinkListHeaderType;
  links: Array<FooterLinkType>;
};

export const footerLinks: FooterLinkListGroupsType = {
  popularCategories: {
    header: {
      title: 'Populära kategorier',
    },
    links: [
      {
        title: 'Ansiktsvård',
        href: '/produkter/hudvard/ansiktsvard/',
      },
      {
        title: 'Barn & föräldrar',
        href: '/produkter/barn-foraldrar/',
      },
      {
        title: 'Kosttillskott & vitaminer',
        href: '/produkter/kosttillskott-vitaminer/',
      },
      {
        title: 'Förkylning',
        href: '/produkter/forkylning-nasa/forkylning/',
      },
    ],
  },
  services: {
    header: {
      title: 'Våra tjänster',
    },
    links: [
      {
        title: 'Se Hjärtats tjänster i apoteken',
        href: '/vara-tjanster/',
        isEpiLink: true,
      },
    ],
  },
  about: {
    header: {
      title: 'Om oss',
    },
    links: [
      {
        title: 'För leverantörer',
        href: '/om-oss/kontakta-supportkontoret/for-leverantorer/',
        isEpiLink: true,
      },
      { title: 'Hållbarhet', href: '/om-oss/hallbarhet/', isEpiLink: true },
      {
        title: 'Karriär & lediga tjänster',
        href: 'https://karriar.icagruppen.se/apotekhjartat/se/sv/home',
      },
      {
        title: 'Kontakt supportkontoret',
        href: '/om-oss/kontakta-supportkontoret/',
        isEpiLink: true,
      },
      {
        title: 'Just nu på Hjärtat',
        href: '/om-oss/just-nu-pa-hjartat/',
        isEpiLink: true,
      },
      {
        title: 'Press och bildbank',
        href: '/om-oss/pressrum/pressmeddelanden/',
        isEpiLink: true,
      },
    ],
  },
  help: {
    header: {
      title: 'Support',
    },
    links: [
      {
        title: 'Hitta apotek',
        href: '/hitta-apotek-hjartat/',
        isEpiLink: true,
      },
      {
        title: 'Kontakta oss',
        href: '/kundservice/kontakta-oss/',
      },
      {
        title: 'Vanliga frågor',
        href: '/kundservice/vanliga-fragor/',
      },
      {
        title: 'Frakt & leverans',
        href: '/kundservice/frakt-leverans/',
      },
      {
        title: 'Betalning',
        href: '/mina-recept/sa-funkar-det/delbetalning-pa-apotek-hjartat/',
        isEpiLink: true,
      },
      {
        title: 'Förskrivare',
        href: '/kundservice/forskrivare/',
      },
      {
        title: 'Receptfrågor',
        href: '/mina-recept/sa-funkar-det/hamta-ut-dina-recept-online/',
        isEpiLink: true,
      },
    ],
  },
};

export const footerShortcutLinks: FooterLinkType[] = [
  { title: 'Hem', href: '/' },
  { title: 'Rådgivning', href: '/tips-och-rad/', feature: 'aph_features_tips_advice_page' },
  { title: 'Klubb Hjärtat', href: '/klubb-hjartat/mina-sidor/' },
  { title: 'Hitta apotek', href: '/hitta-apotek-hjartat/' },
  { title: 'Kontakta oss', href: '/kundservice/kontakta-oss/' },
];

export const footerLegalLinks: FooterLinkType[] = [
  { title: 'Anpassa cookieinställningar', href: '/cookieinstallningar/' },
  { title: 'Information om cookies', href: '/om-oss/information-om-cookies/', isEpiLink: true },
  { title: 'Köpvillkor', href: '/kundservice/kopvillkor/' },
  {
    title: 'Personuppgiftsbehandling',
    href: '/om-oss/var-personuppgiftsbehandling/',
    isEpiLink: true,
  },
];

export const footerSocialLogosLinks: FooterSocialLogosLinkType[] = [
  { href: 'https://instagram.com/apotekhjartat', icon: <InstagramFooter /> },
  { href: 'https://www.youtube.com/user/apotekhjartat', icon: <YoutubeFooter /> },
  { href: 'https://www.facebook.com/apotekhjartat?fref=ts', icon: <FacebookFooter /> },
  { href: 'https://www.linkedin.com/company/apotekhjartat/', icon: <LinkedinFooter /> },
];

export const footerLegalLogosLinks: FooterLegalImageLinks = {
  apotekLegalLogo: {
    href: 'https://www.lakemedelsverket.se/sv/handel-med-lakemedel/apotek',
    imgSrc: '/_next-public/static/images/apotek_legal.png',
    imgWidth: 120,
    imgHeight: 48,
    imgAlt: 'Apotek med tillstånd av Läkemedelsverket',
    text: 'Apotek med tillstånd av Läkemedelsverket',
  },
  vetPharmacyLogo: {
    href: 'https://www.lakemedelsverket.se/sv/handel-med-lakemedel/foretag-som-saljer-lakemedel-via-e-handel/sok-foretag-med-e-handel/e-handlare/a00001/apotek-hjartat-ab',
    imgSrc: '/_next-public/static/images/pharmacy_eu_logo_animal.png',
    imgWidth: 59,
    imgHeight: 48,
    imgAlt: 'Klicka här för att kontrollera om webbplatsen är laglig',
  },
  pharmacyLogo: {
    href: 'https://www.lakemedelsverket.se/sv/handel-med-lakemedel/foretag-som-saljer-lakemedel-via-e-handel/sok-foretag-med-e-handel/e-handlare/a00001/apotek-hjartat-ab',
    imgSrc: '/_next-public/static/images/pharmacy_eu_logo.png',
    imgWidth: 59,
    imgHeight: 48,
    imgAlt: 'Klicka här för att kontrollera om webbplatsen är laglig',
  },
};

export type FooterLinkListHeaderType = {
  title: string;
};

type FooterLinkListGroupsType = {
  popularCategories: FooterLinkListType;
  services: FooterLinkListType;
  about: FooterLinkListType;
  help: FooterLinkListType;
};

export type FooterSocialLogosLinkType = {
  href: string;
  icon: React.ReactChild;
};

type FooterLegalImageLinks = {
  apotekLegalLogo: FooterLegalLogosLinkType;
  vetPharmacyLogo: FooterLegalLogosLinkType;
  pharmacyLogo: FooterLegalLogosLinkType;
};

type FooterLegalLogosLinkType = {
  href: string;
  imgSrc: string;
  imgWidth: number;
  imgHeight: number;
  imgAlt: string;
  text?: string;
};
